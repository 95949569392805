<template>
  <div class="card">
    <loader-component v-if="loading" />
    <div class="card-body">
      <div class="row mt-5 align-items-center justify-content-between">
        <div class="col-md-3">
          <label for="">From</label>
          <input type="date" v-model="filter.start_date" class="form-control" />
        </div>
        <div class="col-md-3">
          <label for="">To</label>
          <input type="date" v-model="filter.end_date" class="form-control" />
        </div>
        <div class="col-md-3">
          <label for="">Select Status</label>
          <b-form-select 
            v-model="filter.status" 
            :options="status_options" 
            name="id" >
            </b-form-select>
        </div>
        <div class="col-md-3 mt-7 row justify-content-center align-items-center">
          <div class="col-6">
            <button class="btn btn-success w-100" @click="getItems()">Filter</button>
          </div>
          <div class="col-6">
            <button class="btn btn-danger w-100" @click="clear">Clear</button>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <b-table
            striped
            hover
            responsive
            :items="items"
            :fields="fields"
            thead-class="bg-primary text-white"
            show-empty
            class="mt-5"
          >
            <template #cell(status)="row">
              <div class="text-center">
                <span class="border-1 rounded bg-success text-uppercase text-white p-1" v-if="row.item.status == 'approved'">
                    Approved
                </span>
                <span class="border-1 rounded bg-warning text-uppercase text-white p-1" v-else-if="row.item.status == 'pending'">
                  Pending
                </span>
                <span class="border-1 rounded bg-danger text-uppercase text-white p-1" v-else-if="row.item.status == 'rejected'">
                  Rejected
                </span>
                <span class="border-1 bg-info rounded text-uppercase p-1 text-white" v-else>
                  {{ row.item.status }}
                </span>
              </div>

            </template>
          </b-table>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-md-12">
          <b-pagination
            v-model="currentPage"
            :total-rows="total_count"
            :per-page="perPage"
            size="lg"
            class="my-0"
            @change="getItems"
          ></b-pagination>
        </div>
      </div>
    </div>
    <vue-snotify></vue-snotify>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import axios from "axios";

export default {
  name: "DoctorReferral",
  data() {
    return {
      loading: false,
      filter: {
        start_date: null,
        end_date: null,
        status: null,
      },
      items: [],
      fields: [
        {
          key: "patient_name",
          label: "Patient",
        },
        {
          key: "uid",
          label: "Application ID",
        },
        {
          key: "total_service_amount",
          label: "Total service amount",
        },
        {
          key: "patient_amount",
          label: "Patient amount",
        },
        {
          key: "expected_discount_amount",
          label: "Discount amount",
        },
        {
          key: "status",
          label: "Status",
        },
        {
          key: "created_at",
          label: "Created date",
        },
      ],
      total_count: 0,
      perPage: 20,
      currentPage: 1,
      status_options: [
        { value: null, text: 'All Status' },
        { value: 'approved', text: 'Approved' },
        { value: 'pending', text: 'Pending' },
        { value: 'rejected', text: 'Rejected' },
        { value: 'served', text: 'Served' },
      ]
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: "Welfare Application",
        route: "/welfare-application",
      },
    ]);
  },
  created() {
    this.getItems();
    localStorage.setItem("tabIndex", 4);
    this.$root.$emit("call-active-menu", 5);
  },
  methods: {
    getItems(value) {
      this.loading = true;
      if (Number(value)) {
        this.currentPage = value;
        var offset = value - 1;
        var correctValue = offset * this.perPage;
      }
      axios
        .get(
          `${
            process.env.VUE_APP_API_URL
          }/api/v2/welfare/applications?api_token=${localStorage.getItem(
            "api_token"
          )}${
            this.filter.start_date
              ? "&start_date=" + this.filter.start_date
              : ""
          }${this.filter.end_date ? "&end_date=" + this.filter.end_date : ""}${
            this.filter.status
              ? "&status=" + this.filter.status
              : ""
          }&limit=20&offset=${correctValue ? correctValue : 0}`
        )
        .then((res) => {
          this.items = res.data.data;
          this.total_count = res.data.total_count;
          this.loading = false;
        });
    },
    clear() {
      this.filter = {};
      this.getItems();
    },
  },
};
</script>

<style scoped></style>
